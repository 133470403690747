<template>
  <v-row v-if="completed" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg" class="hide"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="11" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.tattooer_complete") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn style="height: 30px" outlined @click="$router.back()">{{
          $t("back")
        }}</v-btn>
      </v-row>
    </v-img>
  </v-row>
  <v-row
    v-else-if="already_done && !$store.getters['auth/isLoggedIn']"
    class="final"
    style="overflow: hidden"
  >
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.tattooer_already_complete") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          style="height: 30px"
          outlined
          @click="$router.push({ name: 'Login' })"
          >{{ $tc("inscriptions.go_log_in", 2) }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <v-row v-else-if="already_done" class="final" style="overflow: hidden">
    <v-img height="900px" src="@/assets/background.jpg"
      ><v-row
        justify="center"
        align="center"
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top:8%'
            : $vuetify.breakpoint.lgAndDown
            ? 'margin-top: 5%'
            : ''
        "
      >
        <v-col cols="10" md="2">
          <img width="100%" src="@/assets/logo.png" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="4" class="pa-0" src="@/assets/background.jpg">
          <h1
            style="
              text-transform: uppercase;
              color: white !important;
              font-size: 22px;
            "
          >
            {{ $tc("inscriptions.tattooer_already_complete") }}<br />
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-btn
          style="height: 30px"
          outlined
          @click="
            $router.push({
              name: 'studioView',
              params: { id: $route.params.studio },
            })
          "
          >{{ $t("go_studio") }}</v-btn
        >
      </v-row>
    </v-img>
  </v-row>
  <studio-card id="invite-tattooer" v-else-if="tattooer.tattooerStudio">
    <v-stepper v-model="e1" alt-labels>
      <v-stepper-header>
        <v-spacer></v-spacer>
        <v-stepper-step step="1" :complete="e1 > 1"
          ><div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.share_info") }}
          </div></v-stepper-step
        >

        <!--<v-stepper-step step="2" :complete="e1 > 2"
          ><div
            style="
              text-align: center;
              color: var(--v-primary-base);
              text-transform: uppercase;
            "
          >
            {{ $t("tattooers.invites.stance_info") }}
          </div></v-stepper-step
        >
        <v-divider></v-divider>-->

        <v-spacer></v-spacer>
        <v-row
          v-if="$vuetify.breakpoint.smAndDown"
          style="display: block; width: 100%; margin-top: -30px"
          justify="center"
          ><v-col>
            <div
              v-if="e1 === 1"
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.share_info") }}
            </div>
            <!--<div
              v-else-if="e1 === 2"
              style="
                text-align: center;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              {{ $t("tattooers.invites.stance_info") }}
            </div>-->
          </v-col></v-row
        >
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <information-share
            :tattooerStudio="tattooer.tattooerStudio"
            @validated="validate"
          ></information-share>
        </v-stepper-content>
        <!--<v-stepper-content step="2">
          <studio-inscription
            :tattooerStudio="tattooer.tattooerStudio"
            @validated="e1++"
            @back="e1--"
          ></studio-inscription>
        </v-stepper-content>-->
      </v-stepper-items>
    </v-stepper>
  </studio-card>
</template>

<script>
import constants from "@/constants";
import { mapActions, mapState } from "vuex";
import InformationShare from "../../../components/inscription/tattooer/InformationShare.vue";
export default {
  name: "TattooerAcceptInvite",
  components: {
    TattooersForm: () => import("@/components/tattooer/Form"),
    StudioInscription: () =>
      import("@/components/inscription/tattooer/StudioInscription"),
    InformationShare: () =>
      import("@/components/inscription/tattooer/InformationShare"),
    StudioCard: () => import("@/components/inscription/studio/Card"),
  },
  mounted() {
    this.fetchStudio();

    if (this.$route.name === "accept_tattooer_invite") {
      //RUTA AMB ID TATTOER
      //console.log("LOADING EXISTING TATTOOER");
      if (this.$store.getters["auth/isLoggedIn"]) {
        // COMPROVAR ID URL ES ID USER
        console.log(this.$store.state.auth.user.id);
        if (this.$route.params.tattooer !== this.$store.state.auth.user.id) {
          this.$router.push("/");
        }
      } else {
        this.fetchTattooer();
      }

      this.fetchRelation();
    } else {
      //RUTA SENSE ID TATTOER
      if (this.$store.getters["auth/isLoggedIn"]) {
        this.getTattooer(this.$store.state.auth.user.id).then((response) => {
          this.tattooer = response;
          this.e1 = 2;
          this.$set(this.tattooer, "tattooerStudio", {
            timetable: [[], [], [], [], [], [], []],
            studio_id: this.$route.params.studio,
          });
        });
      } else {
        this.tattooer = {
          tattooerStudio: {
            timetable: [[], [], [], [], [], [], []],

            studio_id: this.$route.params.studio,
          },
          token_payment: [],
          user: {
            role_id: constants.roles.TATTOOER,
          },
          social_networks: {},
        };
      }
    }
  },
  computed: {
    ...mapState("inscriptions", ["studioState", "tattooerState"]),
    tattooer: {
      get() {
        return this.tattooerState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_TATTOOER", value);
      },
    },

    studio: {
      get() {
        return this.studioState;
      },
      set(value) {
        this.$store.commit("inscriptions/SET_STUDIO", value);
      },
    },
  },
  methods: {
    ...mapActions("auth", ["checkHash"]),
    check() {
      let valors = [];
      if (this.$route.params.tattooer) valors.push(this.$route.params.tattooer);
      valors.push(this.$route.params.studio);
      this.checkHash({ valors, hash: this.$route.params.hash }).then((data) => {
        //console.log(data);
        if (!data.correct) {
          this.$router.push({ name: "404 Error" });
        }
      });
    },
    ...mapActions("studios", ["getStudio"]),
    fetchStudio() {
      this.getStudio(this.$route.params.studio).then((result) => {
        this.studio = result;
      });
    },

    ...mapActions("tattooers", ["getTattooer"]),
    fetchTattooer() {
      this.getTattooer(this.$route.params.tattooer).then((result) => {
        result.tattooerStudio = {
          timetable: [[], [], [], [], [], [], []],

          studio_id: this.$route.params.studio,
        };
        this.tattooer = result;
      });
    },
    ...mapActions("inscriptions", [
      "getTattooerStudio",
      "newTattooer",
      "updateTattooer",
      "acceptStudio",
    ]),
    fetchRelation() {
      this.getTattooerStudio({
        tattooer_id: this.$route.params.tattooer,
        studio_id: this.$route.params.studio,
      }).then((result) => {
        if (result) {
          if (result.to === null) {
            result.indefinido = true;
          }
          this.$set(this.tattooer, "tattooerStudio", result);
          this.tattooer.tattooerStudio = result;
          this.$store.commit("inscriptions/SET_TATTOOER", this.tattooer);
        } else {
          this.already_done = true;
        }
      });
    },
    validate() {
      if (
        this.$store.getters["auth/isLoggedIn"] ||
        this.$route.name === "accept_tattooer_invite"
      ) {
        if (this.$route.params.id) {
          this.accept.notification_id = this.$route.params.id;
        }
        this.accept.tattooer_id = this.$route.params.tattooer;
        this.accept.studio_id = this.$route.params.studio;
        this.acceptStudio({
          accept: this.accept,
        }).then((response) => this.response(response));
      }
    },
    response(response) {
      this.completed = true;
    },
    login() {
      this.$router.push({ name: "Login", params: { inscription: true } });
    },
  },
  data() {
    return {
      e1: 1,
      accept: {
        tattooer_id: null,
        studio_id: null,
      },
      completed: false,
      already_done: false,
    };
  },
};
</script>
<style lang="sass">
#inscription .v-responsive__sizer
  display: none
#invite-tattooer
  .v-input--selection-controls__input .v-icon
    margin-top: -9px !important
  label
    display: block
    height: 25px
</style>