var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.completed)?_c('v-row',{staticClass:"final",staticStyle:{"overflow":"hidden"}},[_c('v-img',{staticClass:"hide",attrs:{"height":"900px","src":require("@/assets/background.jpg")}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top:8%'
          : _vm.$vuetify.breakpoint.lgAndDown
          ? 'margin-top: 5%'
          : ''),attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/logo.png")}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"11","md":"4","src":"@/assets/background.jpg"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","color":"white !important","font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.$tc("inscriptions.tattooer_complete"))),_c('br')])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1)],1):(_vm.already_done && !_vm.$store.getters['auth/isLoggedIn'])?_c('v-row',{staticClass:"final",staticStyle:{"overflow":"hidden"}},[_c('v-img',{attrs:{"height":"900px","src":require("@/assets/background.jpg")}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top:8%'
          : _vm.$vuetify.breakpoint.lgAndDown
          ? 'margin-top: 5%'
          : ''),attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/logo.png")}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"4","src":"@/assets/background.jpg"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","color":"white !important","font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.$tc("inscriptions.tattooer_already_complete"))),_c('br')])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_vm._v(_vm._s(_vm.$tc("inscriptions.go_log_in", 2)))])],1)],1)],1):(_vm.already_done)?_c('v-row',{staticClass:"final",staticStyle:{"overflow":"hidden"}},[_c('v-img',{attrs:{"height":"900px","src":require("@/assets/background.jpg")}},[_c('v-row',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top:8%'
          : _vm.$vuetify.breakpoint.lgAndDown
          ? 'margin-top: 5%'
          : ''),attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/logo.png")}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"4","src":"@/assets/background.jpg"}},[_c('h1',{staticStyle:{"text-transform":"uppercase","color":"white !important","font-size":"22px"}},[_vm._v(" "+_vm._s(_vm.$tc("inscriptions.tattooer_already_complete"))),_c('br')])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({
            name: 'studioView',
            params: { id: _vm.$route.params.studio },
          })}}},[_vm._v(_vm._s(_vm.$t("go_studio")))])],1)],1)],1):(_vm.tattooer.tattooerStudio)?_c('studio-card',{attrs:{"id":"invite-tattooer"}},[_c('v-stepper',{attrs:{"alt-labels":""},model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('v-stepper-header',[_c('v-spacer'),_c('v-stepper-step',{attrs:{"step":"1","complete":_vm.e1 > 1}},[_c('div',{staticStyle:{"text-align":"center","color":"var(--v-primary-base)","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.invites.share_info"))+" ")])]),_c('v-spacer'),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticStyle:{"display":"block","width":"100%","margin-top":"-30px"},attrs:{"justify":"center"}},[_c('v-col',[(_vm.e1 === 1)?_c('div',{staticStyle:{"text-align":"center","color":"var(--v-primary-base)","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.invites.share_info"))+" ")]):_vm._e()])],1):_vm._e()],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('information-share',{attrs:{"tattooerStudio":_vm.tattooer.tattooerStudio},on:{"validated":_vm.validate}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }